.login-out {
  width: 100%;
  padding-top: 8vh;
}

.login-out .no-success .title {
  padding-left: 0.426667rem;
  font-size: 0.586667rem;
  font-weight: 600;
  text-align: left;
  color: #333333;
}

.login-out .no-success .mobile-img {
  width: 2.133333rem;
  margin: 0.8rem auto;
}

.login-out .no-success .mobile-img img {
  width: 100%;
}

.login-out .no-success .input-wrapper {
  margin: 0.213333rem auto 0 auto;
  color: #999999;
  font-size: 0.48rem;
  text-align: center;
}

.login-out .no-success .quit {
  background-color: #1891ff;
  color: #ffffff;
  border-radius: 0.106667rem;
  width: 90%;
  padding: 0.48rem 0;
  font-size: 0.4rem;
  margin: 1rem auto 0 auto;
  text-align: center;
}

.login-out .success .mobile-img {
  width: 2.133333rem;
  margin: 0.8rem auto;
}

.login-out .success .mobile-img img {
  width: 100%;
}

.login-out .success .title {
  padding-left: 0.426667rem;
  font-size: 0.586667rem;
  font-weight: 600;
  text-align: center;
  color: #333333;
}